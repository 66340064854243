import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, DashboardCard, Button } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';

import { PreviewCardData, PreviewCardPlaceholder } from 'components/constants';
import { getTickets } from 'api-client/support';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';
import PreviewItem from 'components/shared/PreviewCard/PreviewItem';
import TicketDetailsModal from './TicketDetailsModal';
import useStyles from '../Dashboard/Dashboard.styles';

const Support = ({ tenant }) => {
  const dispatch = useDispatch();
  const classes = useStyles({});

  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [sorting, setSorting] = useState({
    field: 'updated',
    isAsc: false,
    filterValue: '',
    filter: '',
  });

  const limit = 4;
  const status = 'open';

  const handleItemClick = (item) => {
    setSelectedTicket(item);
    setDetailsOpen(true);
  };

  const handleCreateTicket = () => {
    window.location.replace(
      `https://${window.location.host}/v2/support/company-tickets/?createTicket=true`,
    );
  };

  const handleNavigateSupport = () => {
    window.location.replace(`https://${window.location.host}/v2/support/company-tickets/`);
  };

  const handleChangeSortingType = useCallback(
    async ({ field, isAsc, filter, filterValue }) => {
      try {
        setIsLoading(true);
        const data = await getTickets({
          tenant,
          limit,
          field,
          isAsc,
          filter,
          filterValue,
          status,
        });
        setSorting({ field, isAsc, filterValue, filter });
        setTickets(data);
      } catch (error) {
        return error;
      }
      setIsLoading(false);
    },
    [tenant],
  );

  const handleGetTickets = async () => {
    try {
      setIsLoading(true);
      const { field, isAsc, filter, filterValue } = sorting;
      const data = await getTickets({
        tenant,
        limit,
        field,
        isAsc,
        filter,
        filterValue,
        status,
      });
      setTickets(data);
    } catch (err) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not get findings!',
          open: true,
        }),
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetTickets();
  }, []);

  return (
    <>
      <Tooltip title="Showing the latest Tickets in the system.">
        <DashboardCard
          cardTitle={PreviewCardData.support.title}
          sortingOptions={PreviewCardData.support.sortingTypes}
          handleChangeSortingType={handleChangeSortingType}
          showSorting={Boolean(tickets?.length)}
          isLoading={isLoading}
          emptyViewConfig={!tickets?.length ? PreviewCardPlaceholder.support : null}
          footerContent={
            tickets?.length ? (
              <div className={classes.supportCardFooter}>
                <Button
                  onClick={handleCreateTicket}
                  styleClass="transparentGreenTextSmall"
                  width="fit-content"
                >
                  <Icon iconName="add" className={classes.btnIcon} /> New ticket
                </Button>
                <Button onClick={handleNavigateSupport} styleClass="textGrey">
                  <span>Open</span>
                  <Icon iconName="open_in_new" className={classes.btnIconRight} />
                </Button>
              </div>
            ) : null
          }
        >
          <div className={classes.rowsContainer}>
            {tickets?.map((item) => (
              <PreviewItem
                itemData={item}
                onItemClick={handleItemClick}
                type={PreviewCardData.support.name}
              />
            ))}
          </div>
        </DashboardCard>
      </Tooltip>
      <TicketDetailsModal
        isOpen={detailsOpen}
        ticket={selectedTicket}
        handleClose={() => setDetailsOpen(false)}
      />
    </>
  );
};

export default Support;
