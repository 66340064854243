const standartDashboardRoles = [
  'owner.user',
  'provider.user',
  'consumer.user',
  'owner.helpdesk_operator',
  'provider.helpdesk_operator',
  'owner.helpdesk_operator_own',
  'provider.helpdesk_operator_own',
];

const consumerAdminDashboardRoles = ['consumer.admin'];

const providerAdminDashboardRoles = ['owner.admin', 'provider.admin'];

const accountManagerDashboardRoles = [
  'owner.product_manager',
  'provider.product_manager',
  'owner.sales_manager',
  'provider.sales_manager',
  'owner.account_manager',
  'provider.account_manager',
];

export enum DashboardTypes {
  STANDART_DASHBOARD = 'standart_dashboard',
  CONSUMER_ADMIN_DASHBOARD = 'consumer_admin_dashboard',
  PROVIDER_ADMIN_DASHBOARD = 'provider_admin_dashboard',
  ACCOUNT_MANAGER_DASHBOARD = 'account_manager_dashboard',
}

export const handleGetDashboardType = ({ role }) => {
  let dashboardType;

  switch (true) {
    case standartDashboardRoles.includes(role):
      dashboardType = DashboardTypes.STANDART_DASHBOARD;
      break;
    case consumerAdminDashboardRoles.includes(role):
      dashboardType = DashboardTypes.CONSUMER_ADMIN_DASHBOARD;
      break;
    case providerAdminDashboardRoles.includes(role):
      dashboardType = DashboardTypes.PROVIDER_ADMIN_DASHBOARD;
      break;
    case accountManagerDashboardRoles.includes(role):
      dashboardType = DashboardTypes.ACCOUNT_MANAGER_DASHBOARD;
      break;
    default:
      dashboardType = DashboardTypes.STANDART_DASHBOARD;
      break;
  }

  return dashboardType;
};
