import { Modal, Button } from '@zaveit/uikit';
import { Icon } from '@zaveit/icons';
import format from '@zaveit/format';
import classnames from 'classnames';

import PriorityComponent from 'components/shared/PreviewCard/PriorityComponent';
import useStyles from './Support.styles';

function TicketDetailsModal({ isOpen, handleClose, ticket }) {
  const classes = useStyles();

  const origin = window.location.origin.replace('app', 'api');
  // const origin = 'https://api.zit.red';

  const handleDetailsOpen = () => {
    window.open(`${window.location.origin}/v2/support/company-tickets/${ticket?._id}`)
  }

  return (
    <Modal open={isOpen} handleClose={handleClose}>
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <PriorityComponent priority={ticket?.priority} />
          <div className={classes.issueContainer}>
            <div className={classes.issueTitle}>
              <span>{ticket?.issue || ticket?.title}</span>
              <div
                className={classnames(classes.statusContainer, classes[`${ticket?.status}Status`])}
              >
                <span>{ticket?.status}</span>
              </div>
            </div>
            <div className={classes.date}>{format.date(ticket?.created).long}</div>
          </div>
          <button type="button" className={classes.closeIconBtn} onClick={handleClose}>
            <Icon iconName="close" />{' '}
          </button>
        </div>
        <div className={classes.modalDescription}>
          <div>{ticket?.description}</div>
          <div className={classes.imagesContainer}>
            {ticket?.files?.map((file) => (
              <img src={`${origin}/v1/support/ticket/image/${file?.url}`} alt="" key={file?._id} className={classes.file} />
            ))}
          </div>
        </div>
        <div className={classes.modalFooter}>
          <Button styleClass="transparentGreyBorderBtn" onClick={handleClose}>Cancel</Button>
          <Button styleClass="activeGreen" width="fit-content" onClick={handleDetailsOpen}>
            Open details
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default TicketDetailsModal;
