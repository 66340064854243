import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Feature } from '@zaveit/feature';

import Findings from 'components/Findings/Findings';
import Support from 'components/Support/Support';
import Customers from 'components/Customers/Customers';
import ScanButton from 'components/ScanButton/ScanButton';
import Statistic from 'components/Statistic';

import { TStatisticValues, TFinding, TCustomer, TTarget } from 'components/types';
import {
  getDashboardData,
  updateProviderFindingsData,
  updateConsumerFindingsData,
  getTargetsData,
} from 'components/helpers';
import { Features } from 'constants/features';
import { Roles } from 'constants/index';
import EmptyViewDashboard from 'components/EmptyViewDashboard';
import { getFindings, getRisc } from 'api-client/risc';
import { setStatusBar } from 'redux/statusBar/statusBar.actions';

import useStyles from './Dashboard.styles';
import CoursesBanner from './CoursesBanner';

function ProviderAdminDashboard() {
  const classes = useStyles({});
  // @ts-expect-error
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [statistic, setStatistic] = useState<TStatisticValues>({
    risc: 0,
    findings: 0,
    tenants: 0,
    targets: 0,
    tickets: 0,
  });
  const [findings, setFindings] = useState<TFinding[]>([]);
  const [customers, setCustomers] = useState<TCustomer[]>([]);
  const [targets, setTargets] = useState<TTarget[]>();

  useEffect(() => {
    const getData = async () => {
      try {
        const {
          risc: riscData,
          findings: findingsData,
          customers: customersData,
          backup: backupData,
          vsaas: vsaasData,
          accounts: accountsData,
          tickets: ticketsData,
        } = await getDashboardData(userInfo?.tenant, userInfo?.msp);

        if (userInfo?.msp) {
          setFindings(updateProviderFindingsData(findingsData?.data, customersData));
        } else {
          const targetsData = getTargetsData(findingsData?.data, backupData, vsaasData);
          setFindings(updateConsumerFindingsData(findingsData?.data, targetsData));
          setTargets(targetsData);
        }
        setStatistic((prev) => ({
          ...prev,
          risc: riscData?.[0]?.risc_score?.toFixed(1) || 0,
          findings: findingsData?.metadata?.paginator?.total || 0,
          tenants: accountsData?.data?.length || 0,
          tickets: ticketsData?.length || 0,
        }));
        setCustomers(customersData);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    };

    if (userInfo?.tenant) {
      getData();
    }
  }, [userInfo?.tenant, userInfo?.msp]);

  const refetchRisc = useCallback(async () => {
    try {
      const riscPromise = getRisc(userInfo?.tenant);
      const findingsPromise = getFindings(userInfo?.tenant, null, null, 5);

      const [riscData, findingsData] = await Promise.all([riscPromise, findingsPromise]);

      setStatistic((prev) => ({
        ...prev,
        risc: riscData?.[0]?.risc_score?.toFixed(1) || 0,
        findings: findingsData?.metadata?.paginator?.total || 0,
      }));
    } catch (e) {
      dispatch(
        setStatusBar({
          type: 'error',
          message: 'Can not update risc!',
          open: true,
        }),
      );
    }
  }, [dispatch, userInfo?.tenant]);

  return (
    <div className={classes.rightSide}>
      <div className={classes.titleContainer}>
        <Feature feature={Features.vulnerability}>
          <ScanButton />
        </Feature>
      </div>
      <Statistic values={statistic} isMsp={userInfo?.msp} isLoading={isLoading} />
      <div className={classes.sectionsContainer}>
        <Feature feature={Features.risc}>
          <Findings
            tenant={userInfo?.tenant}
            values={findings}
            customers={customers}
            targets={targets}
            handleChange={setFindings}
            isMsp={userInfo?.msp}
            isLoading={isLoading}
            refetchRisc={refetchRisc}
          />
        </Feature>
        <CoursesBanner
          isSmall
          buttonTitle="Explore"
          title="BUILD - SELL - MANAGE"
          subtitle="New to the platform? Dive into our guide on how to Get Started as a new IT reseller or Managed Service Provider (MSP)"
        />
      </div>
      <div className={classes.bottomCardsContainer}>
        <Feature feature={Features.support}>
          <Support tenant={userInfo?.tenant} />
        </Feature>
        <Feature feature={Features.accountManagement}>
          <Customers tenant={userInfo?.tenant} />
        </Feature>
      </div>
      {userInfo?.role === Roles.CONSUMER_USER && <EmptyViewDashboard />}
    </div>
  );
}

export default ProviderAdminDashboard;
