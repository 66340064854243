// @ts-nocheck
import { makeStyles } from '@material-ui/core';
import Colors from 'styles/Color.styles';
import 'styles/fonts.scss';

const useStyles = makeStyles(() => ({
  staticticsContainer: {
    marginBottom: '24px',
    display: 'grid',
    justifyContent: 'space-between',
    gap: '24px',
    gridTemplateColumns: 'repeat(4, calc(24% - 18px))',
  },
  comingSoonTitle: {
    '& > div > div > div:nth-child(2)': {
      position: 'relative',
      width: 'max-content',
      color: Colors.backgroundGrey,
      fontFamily: 'Poppins-SemiBold, sans-serif',
      fontSize: '24px',
      lineHeight: '48px',
      zIndex: 1,
    },
  },
  placeholderIcon: {
    position: 'absolute',
    top: '14px',
    right: 0,
  },
}));

export default useStyles;
