import { DashboardTypes } from 'helpers/handleGetDashboardType';

import StandartDashboard from './StandartDashboard';
import ProviderAdminDashboard from './ProviderAdminDashboard';
import ConsumerAdminDashboard from './ConsumerAdminDashboard';

type Props = {
  dashboardType: DashboardTypes;
};

function DashboardContainer({ dashboardType }: Props) {
  if (dashboardType === DashboardTypes.CONSUMER_ADMIN_DASHBOARD) {
    return <ConsumerAdminDashboard />;
  }

  if (dashboardType === DashboardTypes.PROVIDER_ADMIN_DASHBOARD) {
    return <ProviderAdminDashboard />;
  }

  return <StandartDashboard />;
}

export default DashboardContainer;
