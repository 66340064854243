/* eslint-disable @typescript-eslint/indent */
import { useState, useEffect, useCallback } from 'react';
import format from '@zaveit/format';
import { Tooltip, DashboardCard, Button } from '@zaveit/uikit';
import { PreviewCardData, PreviewCardPlaceholder, Ranges } from 'components/constants';

import { getCustomersData } from 'components/helpers';
import { TAccount, TRisc } from 'components/types';
import { Icon } from '@zaveit/icons';

import CustomersTable from './CustomersTable';
import useStyles from '../Dashboard/Dashboard.styles';

type TCustomersProps = {
  tenant: string;
};

const Customers = ({ tenant }: TCustomersProps) => {
  const classes = useStyles({});
  const [data, setData] = useState<TAccount[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const getCustomers = useCallback((risc, accounts) => {
    const monthsCount = 12;
    const riscData = new Map<string, TRisc>(risc?.map((value) => [value.metadata.tenant, value]));

    const updatedAccounts = accounts
      .map((currAccount) => ({
        _id: currAccount._id,
        tenant: currAccount.tenant,
        name: currAccount.name,
        users: currAccount.users.count,
        arr: currAccount.orders?.price?.total
          ? format.price(currAccount.orders.price.total * monthsCount).short
          : 0,
        risc_score: riscData?.get(currAccount.tenant)?.risc_score,
      }))
      .slice(Ranges.tables.min, Ranges.tables.max);

    setData(updatedAccounts);
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const { risc: riscData, customers: customersData } = await getCustomersData(tenant);

        getCustomers(riscData, customersData);
        setLoading(false);
      } catch (error) {
        return error;
      }
      setLoading(false);
    };

    if (tenant) {
      getData();
    }
  }, [getCustomers, tenant]);

  return (
    <Tooltip title="Showing your service consumption numbers. Includes usage of licenses and resources.">
      <DashboardCard
        cardTitle={PreviewCardData.customers.title}
        isLoading={loading}
        emptyViewConfig={!data?.length ? PreviewCardPlaceholder.customers : null}
        footerContent={
          data?.length ? (
            <div className={classes.buttonCardContainer}>
              <Button
                onClick={() =>
                  window.location.replace(
                    `https://${window.location.host}/account-manager/accounts`,
                  )
                }
                styleClass="textGrey"
              >
                <span> View all</span>
                <Icon iconName="chevron_right" className={classes.btnIcon} />
              </Button>
            </div>
          ) : null
        }
      >
        <CustomersTable customers={data} />
      </DashboardCard>
    </Tooltip>
  );
};

export default Customers;
