import * as React from 'react';
import useStyles from './Modal.styles';

type TModalTitle = {
  children: any;
};

function ModalTitle({ children }: TModalTitle) {
  const classes = useStyles();
  return <div className={classes.modalTitle}>{children}</div>;
}

export default ModalTitle;
