import axios from 'axios';

import Config from '../config/index';

const instance = axios.create({ baseURL: Config.baseSupportUrl, withCredentials: true });

export const getTickets = async ({ status = undefined, tenant = '', limit = null, field = '', isAsc = null, filter = '', filterValue = '' }) => {
  try {
    const sortParams = field ? `${isAsc ? '' : '-'}${field}` : '';
    const { data } = await instance.get('/v1/support/ticket', {
      params: {
        'filter[status]': status,
        'filter[tenant]': tenant,
        limit,
        sort: sortParams,
        [filter]: filterValue,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getUserTickets = async ({ status = undefined, limit = 5, field = '', isAsc = null, filter = '', filterValue = '' }) => {
  try {
    const sortParams = field ? `${isAsc ? '' : '-'}${field}` : '';
    const { data } = await instance.get('/v1/support/ticket/my', {
      params: {
        'filter[status]': status,
        limit,
        sort: sortParams,
        [filter]: filterValue,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
