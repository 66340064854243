import { makeStyles } from '@material-ui/core';
import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => ({
  icon: {
    width: '24px',
    height: '24px',
    marginRight: '8px',
  },
  error: {
    position: 'absolute',
    textAlign: 'center',
    bottom: '14px',
    left: '0px',
    right: '0px',
    fontSize: '16px',
    fontFamily: 'Lato-Normal, sans-serif',
    letterSpacing: '0.5px',
    color: Colors.red,
  },
}));

export default useStyles;
