import * as React from 'react';
import useStyles from './Modal.styles';

type TModalSubTitle = {
  children: any;
};

function ModalSubTitle({ children }: TModalSubTitle) {
  const classes = useStyles();
  return <div className={classes.modalSubtitle}>{children}</div>;
}

export default ModalSubTitle;
