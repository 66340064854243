import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { StylesProvider, createGenerateClassName } from '@material-ui/core';
import { GlobalStyle } from './components/CookieDispenser/CookieDispenser.style';
import { store } from './redux/store';
import './index.scss';
import App from './App';
import CookieDispenser from './components/CookieDispenser/CookieDispenser';
import config from './config';

dayjs.extend(utc);

const generateClassName = createGenerateClassName({
  productionPrefix: 'dash',
});

const RedirectWrapper = () => {
  const pathname = window.location.pathname;

  if (/\/sso(\/|$)/.test(pathname)) {
    const initiateSso = async () => {
      const res = await axios.get(`${config.baseAuthUrl}/v1/sso`, {
        headers: {
          'origin-url': `${window.location.origin}/dashboard`,
        },
      });
      window.location.href = res.data;
      return;
    };

    initiateSso();
  }

  if (/\/dispense(\/|$)/.test(pathname)) {
    const sitename = process.env.REACT_APP_IS_RED_ENV ? 'app.zit.red' : window.location.host;
    return (
      <>
        <GlobalStyle />
        <CookieDispenser />
      </>
    );
  }

  return (
    <Router basename="/dashboard">
      <StylesProvider generateClassName={generateClassName}>
        <Provider store={store}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </Provider>
      </StylesProvider>
    </Router>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <RedirectWrapper />
  </React.StrictMode>,
  document.getElementById('root')
);