import { useState, useEffect } from 'react';
import { Icon } from '@zaveit/icons';
import _ from 'lodash';
import classNames from 'classnames';
import { Button, EmptyView, CroppableText, DashboardCard } from '@zaveit/uikit';

import { getTrainingCourses } from 'api-client/training';
import { getUserTickets } from 'api-client/support';
import { PreviewCardPlaceholder } from 'components/constants';
import { handleGetDiffTime } from 'components/helpers/date';
import CoursesBanner from './CoursesBanner';
import useStyles from './Dashboard.styles';
import CourseCard from './CourseCard';

function StandartDashboard() {
  const classes = useStyles({});
  const [courses, setCourses] = useState([]);
  const [tickets, setTickets] = useState([]);

  const handleGetCourses = async () => {
    try {
      const data = await getTrainingCourses({});
      setCourses(data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const hadleGetUserTickets = async () => {
    try {
      const data = await getUserTickets({ limit: 5, status: 'open', field: 'updated' });
      setTickets(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCreateTicket = () => {
    window.location.replace(
      `https://${window.location.host}/v2/support/my-tickets/?createTicket=true`,
    );
  };

  const handleNavigateSupport = () => {
    window.location.replace(`https://${window.location.host}/v2/support/my-tickets`);
  };

  useEffect(() => {
    handleGetCourses();
    hadleGetUserTickets();
  }, []);

  const lastCourses = _.orderBy(courses, ['updated'], ['desc']);

  return (
    <div className={classes.rightSide}>
      <CoursesBanner
        title="Empower your Digital Freedom and Confidence"
        subtitle="Our courses is designed to prepare you to prevent and contain cyber-attacks by building
          fundamental awareness across your organization."
      />
      <div className={classes.contentCardsContainer}>
        {courses?.length ? (
          lastCourses.slice(0, 2).map((item) => <CourseCard item={item} />)
        ) : (
          <div className={classNames(classes.cardContainer, classes.cardContainerEmpty)}>
            <EmptyView
              isSection={false}
              maxWidth="280px"
              iconName="menu_book"
              title="Want a new course?"
              description="We could not find more available courses for you at this moment. Create a ticket or contact your closest administrator."
            />
          </div>
        )}
        <DashboardCard
          isLoading={false}
          footerContent={
            tickets?.length ? (
              <div className={classes.supportCardFooter}>
                <Button
                  onClick={handleCreateTicket}
                  styleClass="transparentGreenTextSmall"
                  width="fit-content"
                >
                  <Icon iconName="add" className={classes.btnIcon} /> New ticket
                </Button>
                <Button onClick={handleNavigateSupport} styleClass="textGrey">
                  Go to support
                </Button>
              </div>
            ) : null
          }
          cardTitle="Support"
          titleIconName="confirmation_number"
          iconColor="#754BF2"
        >
          {tickets?.length ? (
            <div className={classes.ticketsContainer}>
              {tickets?.map((item) => (
                <div>
                  <CroppableText value={item?.title} className={classes.ticketTitle} />
                  <div className={classes.ticketTime}>{handleGetDiffTime(item?.updated)}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={classes.emptyContainer}>
              <EmptyView
                isSection={false}
                maxWidth="280px"
                {...PreviewCardPlaceholder.supportStandart}
              />
              <Button styleClass="activeGreen" width="fit-content" onClick={handleCreateTicket}>
                <Icon iconName="add" className={classes.btnIcon} />
                <span>Create ticket</span>
              </Button>
            </div>
          )}
        </DashboardCard>
      </div>
    </div>
  );
}

export default StandartDashboard;
