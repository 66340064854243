import Colors from 'styles/Color.styles';
import { Shadows } from 'styles/constants';
import 'styles/fonts.scss';

const styles = () => ({
  cellRootHead: {
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    color: Colors.subtitleGrey,
    backgroundColor: Colors.lightBackgroundGrey,
    borderBottom: 'none',
    '&.MuiTableCell-root': {
      padding: '16px',
    },
    '&:not(:first-of-type)': {
      textAlign: 'center',
    },
  },
  table: {
    minWidth: '100%',
    '& .MuiPaper-elevation1': {
      boxShadow: Shadows.cardShadow,
    },
  },
  cellRoot: {
    position: 'relative',
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '14px',
    color: Colors.backgroundGrey,
    padding: '0px 16px',
    borderBottom: 'none',
  },
  cellLast: {
    borderRadius: '0px 8px 8px 0px',
    paddingRight: '16px',
  },
  cellFirst: {
    borderRadius: '8px 0px 0px 8px',
  },
  sortIcon: {
    '&.MuiSvgIcon-root': {
      height: '18px',
    },
  },
  bodyRow: {
    height: '54px',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: Colors.backgroundLightGrey,
    },
  },
  headRow: {
    height: '56px',
  },
});

export default styles;
