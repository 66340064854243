import { useState } from 'react';
import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import ModalTitle from 'components/shared/Modal/ModalTitle';
import ModalSubTitle from 'components/shared/Modal/ModalSubTitle';
import ModalContainer from 'components/shared/Modal/ModalContainer';
import useStyles from './ScanButton.styles';

function ScanButton() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [errorScan, setError] = useState('');
  return (
    <>
      {/* <Button type="button" styleClass="activeGreenRound" onClick={() => setOpen(true)}>
        <img src={logoBtn} alt="logo" className={classes.icon} />
        Scan all
      </Button> */}
      <Modal open={open} handleClose={() => setOpen(false)}>
        <ModalContainer>
          <ModalTitle>Are you sure?</ModalTitle>
          <ModalSubTitle>
            Do you really want to scan all targets? This process can take time.
          </ModalSubTitle>
          {errorScan && <div className={classes.error}>{errorScan}</div>}
          <Button
            styleClass="activeGreen"
            width="100%"
            height="48px"
            onClick={async () => {
              // try {
              //   await startScanAll();
              //   setOpen(false);
              //   setError('');
              // } catch (error) {
              //   setOpen(true);
              //   setError(error.response.data.message);
              //   console.error(error.response);
              // }
            }}
          >
            Scan now
          </Button>
          <Button
            styleClass="transparentBtn"
            marginTop="8px"
            onClick={() => {
              setError('');
              setOpen(false);
            }}
          >
            Cancel
          </Button>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default ScanButton;
