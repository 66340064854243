import { useEffect, useState, useMemo, useCallback, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Header, RightSideBar } from '@zaveit/header';
import { Loader, ThemeProvider } from '@zaveit/uikit';
import { Login } from '@zaveit/login';
import { FeatureContextProvider, FeatureRoute } from '@zaveit/feature';
import format from '@zaveit/format';

import { getData, removeData, saveData } from 'helpers/localStorageHelper';
import { DashboardTypes, handleGetDashboardType } from 'helpers/handleGetDashboardType';
import { RootState, AuthState, AccountState } from 'redux/types';
import DashboardContainer from 'components/Dashboard';
import FindingsTable from 'components/FindingsTable';
import StatusBar from 'components/shared/StatusBar/StatusBar';
import GettingStarted from 'components/GettingStarted';
import ConsumptionPage from 'components/ConsumptionPage/ConsumptionPage';
import {
  setIsLoginFormOpen,
  setSession,
  getUserInfoRequest,
  getCurrentTenantAccountRequest,
  getUserInfoSuccess,
} from 'redux/auth/auth.actions';
import {
  getAccountsRequest,
  getSelectedViewAccountsRequest,
  setSelectedAccountView,
} from 'redux/account/account.actions';
import { logOut } from 'api-client/auth';
import {
  getSiteSettings,
  getGettingStartedInfo,
  updateGettingStartedStatus,
} from 'api-client/user';
import Config from 'config/index';
import { handleGetRightSidebarData } from 'helpers/rightSidebar';
import { Features } from 'constants/features';

import useStyles from './App.styles';

enum SideBarModes {
  FULL_VIEW = 'full_view',
  ICONS_VIEW = 'icons_view',
}

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [siteSettings, setSettings] = useState(null);
  const isSidebarOpen = getData('sidebarOpen') != null ? getData('sidebarOpen') : true;
  const isOpen = window.location.pathname === '/dashboard/getting-started' ? false : isSidebarOpen;
  const [isSidebarOpened, setIsSidebarOpened] = useState(isOpen);
  const [isRightSidebarOpened, setIsRightSidebarOpened] = useState<boolean>(false);
  const [sideBarViewAccounts, setSideBarViewAccounts] = useState([]);
  const [search, setSearch] = useState<string>('');
  const [sideBarMode, setSideBarMode] = useState(SideBarModes.FULL_VIEW);
  const [selectedHelpBlock, setSelectedHelpBlock] = useState('');
  const [gettingStartedData, setGettingStarted] = useState(null);
  const [showGettingStarted, setShowGettingStarted] = useState(false);
  const sitename = process.env.REACT_APP_IS_RED_ENV ? 'app.zit.red' : window.location.host;
  const [dashboardType, setDashboardType] = useState(DashboardTypes.STANDART_DASHBOARD);

  const { session, loginOpen, userInfo, companyName } = useSelector(
    (state: RootState): AuthState => state.auth,
  );
  const { accountsList, selectedAccountView } = useSelector(
    (state: RootState): AccountState => state.account,
  );
  const tab = Boolean(userInfo?.child_tenants?.length) ? 2 : 1;

  const [activeTab, setActiveTab] = useState(selectedHelpBlock ? tab : 0);

  const isRightSidebarEnabled = location.pathname === '/consumption';

  const menuAccounts = accountsList.map((account) => ({
    name: account?.name,
    tooltipTitle: `${account.name} / ${account?.number}`,
    onClick: () => {
      dispatch(setSelectedAccountView(account));
    },
    type: 'list',
    id: account?.id || account?._id,
  }));

  format.locale('no');

  const handleOpenRightSidebar = useCallback(
    (value?: boolean | SyntheticEvent) => {
      const updatedValue = typeof value === 'boolean' ? value : null;

      setIsRightSidebarOpened((prevState) => {
        saveData('isRightSidebarOpened', updatedValue ?? !prevState);
        return updatedValue ?? !prevState;
      });

      if (isRightSidebarOpened) {
        setSelectedHelpBlock('');
      }
    },
    [isRightSidebarOpened],
  );

  const rightSidebarData = handleGetRightSidebarData({
    activeTab,
    setSearch,
    search,
    sideBarViewAccounts,
    selectedAccountView,
    dispatch,
    history,
    selectedHelpBlock,
    handleOpenRightSidebar,
    userInfo,
  });

  const handleGetGettingStartedInfo = async () => {
    try {
      const { data, status } = await getGettingStartedInfo();
      setGettingStarted(data);
      if (status !== 204 && data && !data?.status?.completed && !data?.status?.dismissed) {
        history.push('/getting-started');
        setShowGettingStarted(true);
        setIsSidebarOpened(false);
        removeData(['sidebarOpen']);
      }

      if (
        (status === 204 || !data || data?.status?.completed || data?.status?.dismissed) &&
        (location.pathname === '/getting-started' || location.pathname === '/')
      ) {
        history.push('/dashboard');
        setIsSidebarOpened(true);
        saveData('sidebarOpen', true);
        setShowGettingStarted(false);
      }
    } catch (err) {
      console.error(err);
      history.push('/dashboard');
      setIsSidebarOpened(true);
      saveData('sidebarOpen', true);
      setShowGettingStarted(false);
    }
  };

  useEffect(() => {
    async function handleGetSettings() {
      try {
        const settings = await getSiteSettings({ sitename });
        setSettings(settings?.data);
      } catch (error) {
        console.error(error);
      }
    }

    handleGetSettings();
    dispatch(getUserInfoRequest());
    if (session) {
      handleGetGettingStartedInfo();
    } else {
      history.push({
        pathname: '/',
        state: location.state,
        search: location.search,
      });
    }
    // eslint-disable-next-line
  }, [session]);

  const favicon = useMemo(() => document.getElementById('favicon'), []);
  const appTitle = useMemo(() => document.getElementById('app-title'), []);

  useEffect(() => {
    if (siteSettings?.favicon) {
      favicon.setAttribute('href', `${Config.baseFileUrl}${siteSettings?.favicon}`);
    }

    if (siteSettings?.title) {
      appTitle.innerText = `${siteSettings?.title}`;
    }
    // eslint-disable-next-line
  }, [siteSettings?.favicon, siteSettings?.title]);

  useEffect(() => {
    if (userInfo?.tenant) {
      dispatch(getCurrentTenantAccountRequest(userInfo?.tenant));
    }
  }, [dispatch, userInfo?.tenant]);

  useEffect(() => {
    if (!menuAccounts?.length) {
      dispatch(getAccountsRequest(userInfo?.tenant));
    }
  }, [dispatch, menuAccounts?.length]);

  useEffect(() => {
    if (session && selectedAccountView) {
      dispatch(getSelectedViewAccountsRequest(selectedAccountView?.tenant));
    }
    // eslint-disable-next-line
  }, [selectedAccountView]);

  useEffect(() => {
    if (userInfo?.role) {
      const type = handleGetDashboardType({
        role: userInfo.role,
      });
      setDashboardType(type);
      if (type === DashboardTypes.ACCOUNT_MANAGER_DASHBOARD) {
        window.location.replace(
          `https://${window.location.host}/account-manager/manager-dashboard`,
        );
      }
    }
  }, [userInfo?.products, userInfo?.role]);

  useEffect(() => {
    if (search) {
      let filteredAccounts = [...sideBarViewAccounts];
      filteredAccounts = menuAccounts.filter((item) =>
        item?.name?.toLowerCase().includes(search.trim().toLowerCase()),
      );
      setSideBarViewAccounts(filteredAccounts);
    } else {
      setSideBarViewAccounts(menuAccounts);
    }
  }, [search, accountsList]);

  useEffect(() => {
    if (isSidebarOpened) {
      const view = isRightSidebarOpened ? SideBarModes.ICONS_VIEW : SideBarModes.FULL_VIEW;
      setSideBarMode(view);
    }
  }, [isRightSidebarOpened, isSidebarOpened]);

  const handleLogout = async () => {
    try {
      await logOut();
      dispatch(setSession(false));
      dispatch(setIsLoginFormOpen(true));
      dispatch(getUserInfoSuccess(null));
      removeData(['tab', 'sidebarOpen', 'session']);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSetSession = (newSession: boolean) => {
    dispatch(setSession(newSession));
  };
  const handleSetIsLoginFormOpen = (open) => {
    dispatch(setIsLoginFormOpen(open));
  };

  const handleGettingStartedClick = async () => {
    try {
      await updateGettingStartedStatus({ reset: 'completed' });
      setShowGettingStarted(true);
      history.push('/getting-started');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ThemeProvider colors={siteSettings?.colors}>
      <FeatureContextProvider session={!!userInfo} userFeatures={userInfo?.features}>
        <div
          className={classnames({
            [classes.mainContainerBlur]: !session,
          })}
        >
          <StatusBar />
          <Header
            userInfo={userInfo}
            session={session}
            handleLogout={handleLogout}
            companyName={companyName}
            colors={siteSettings?.colors}
            logoSrc={siteSettings?.logo ? `${Config.baseFileUrl}${siteSettings?.logo}` : ''}
            setIsSidebarOpened={setIsSidebarOpened}
            isSidebarOpened={isSidebarOpened}
            sideBarMode={sideBarMode}
            setSideBarMode={setSideBarMode}
            isRightSidebarOpened={isRightSidebarOpened}
            setIsRightSidebarOpened={setIsRightSidebarOpened}
            handleOpenRightSidebar={isRightSidebarEnabled && handleOpenRightSidebar}
            urlConfig={Config}
            showGettingStarted={showGettingStarted}
            handleGettingStartedClick={gettingStartedData ? handleGettingStartedClick : null}
          />
          {session && (
            <RightSideBar
              isRightSidebarOpened={isRightSidebarOpened}
              data={rightSidebarData}
              onChangeTab={setActiveTab}
            />
          )}
          <Login
            handleLogout={handleLogout}
            open={loginOpen}
            logo={siteSettings?.logo ? `${Config.baseFileUrl}${siteSettings?.logo}` : ''}
            handleSetSession={(newSession) => handleSetSession(newSession)}
            handleSetIsLoginFormOpen={(open) => handleSetIsLoginFormOpen(open)}
            sso={siteSettings?.sso}
            urlConfig={Config}
          />
          {!userInfo ? (
            <Loader />
          ) : (
            <div
              className={classnames({
                [classes.container]: true,
                [classes.containerMenuOpen]: isSidebarOpened,
                [classes.containerRightSidebarOpened]: isRightSidebarOpened,
                [classes.containerSidebarOpenedIconView]:
                  !isRightSidebarOpened &&
                  sideBarMode === SideBarModes.ICONS_VIEW &&
                  isSidebarOpened,
              })}
            >
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  render={() => <DashboardContainer dashboardType={dashboardType} />}
                />
                <Route exact path="/findings" component={FindingsTable} />
                <FeatureRoute
                  exact
                  path="/consumption"
                  feature={Features.consumption}
                  component={ConsumptionPage}
                />
                <Route
                  exact
                  path="/getting-started"
                  render={() => (
                    <GettingStarted
                      data={gettingStartedData}
                      setIsSidebarOpened={setIsSidebarOpened}
                      setShowGettingStarted={setShowGettingStarted}
                    />
                  )}
                />
              </Switch>
            </div>
          )}
        </div>
      </FeatureContextProvider>
    </ThemeProvider>
  );
}

export default App;
