import React from 'react';
import classnames from 'classnames';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';

import { headCells } from './customers-table.config';
import useStyles from './CustomersTable.styles';

const CustomersTableHead = (props) => {
  const classes = useStyles();
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow classes={{ root: classes.headRow }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            classes={{
              root: classnames(classes.cellRootHead, classes[headCell?.extraClass]),
            }}
          >
            {headCell.hasFilter && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                classes={{ icon: classes.sortIcon }}
              >
                {headCell.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomersTableHead;
