import React from 'react';
import { Table, TableBody, TableContainer } from '@material-ui/core';

import { Ranges } from 'components/constants';
import { TAccount } from 'components/types';

import CustomersTableHead from './CustomersTableHead';
import { getComparator, stableSort } from './helpers';
import CustomerTableRow from './CustomerTableRow';
import useStyles from './CustomersTable.styles';

type TCustomersTableProps = {
  customers?: TAccount[];
};

const CustomersTable = ({ customers }: TCustomersTableProps) => {
  const classes = useStyles();
  const [orderDirection, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('risc_score');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer style={{ overflowX: 'auto' }}>
      <Table className={classes.table}>
        {customers?.length ? (
          <CustomersTableHead
            order={orderDirection}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        ) : null}
        <TableBody>
          {stableSort(customers, getComparator(orderDirection, orderBy))
            .slice(Ranges.tables.min, Ranges.tables.max)
            .map((customer: TAccount) => (
              <CustomerTableRow customer={customer} key={customer._id} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomersTable.defaultProps = {
  customers: [],
};

export default CustomersTable;
