import * as React from 'react';
import useStyles from './Modal.styles';

type TModalContainer = {
  children: any;
};

function ModalContainer({ children }: TModalContainer) {
  const classes = useStyles();
  return <div className={classes.modalContainer}>{children}</div>;
}

export default ModalContainer;
