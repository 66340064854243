export const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name',
    extraClass: 'cellFirst',
    hasFilter: true,
  },
  { id: 'arr', numeric: true, disablePadding: true, label: 'ARR', hasFilter: true },
  {
    id: 'users',
    numeric: true,
    disablePadding: true,
    label: 'Users',
    hasFilter: true,
  },
  {
    id: 'risc_score',
    numeric: true,
    disablePadding: true,
    label: 'RISC',
    hasFilter: true,
    extraClass: 'cellLast',
  },
];
