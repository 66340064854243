import { Icon } from '@zaveit/icons';

import useStyles from './EmptyViewDashboard.styles';

function EmptyViewDashboard() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Icon iconName="dashboard" className={classes.icon} />
      <div className={classes.title}>Coming soon...</div>
      <div className={classes.description}>Your dashboard will appear here.</div>
    </div>
  );
}

export default EmptyViewDashboard;
