import { makeStyles } from '@material-ui/core';
import Colors from '../../styles/Color.styles';
import '../../styles/fonts.scss';

const useStyles = makeStyles(() => ({
  modalContainer: {
    width: '712px',
    boxSizing: 'border-box',
  },
  modalHeader: {
    display: 'flex',
    padding: '24px',
    columnGap: '16px',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px -1px 0px rgba(148, 157, 176, 0.24)',
  },
  modalDescription: {
    padding: '24px',
    maxHeight: '336px',
    overflowY: 'auto',
  },
  modalFooter: {
    display: 'flex',
    padding: '24px',
    columnGap: '12px',
    width: '100%',
    boxSizing: 'border-box',
    boxShadow: 'inset 0px 1px 0px rgba(148, 157, 176, 0.24)',
    justifyContent: 'flex-end',
  },
  issueContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  issueTitle: {
    display: 'flex',
    columnGap: '8px',
    color: Colors.backgroundGrey,
    fontFamily: 'Lato-SemiBold, sans-serif',
    fontSize: '14px',
    lineHeight: '22px',
  },
  date: {
    color: Colors.subtitleGrey,
    fontSize: '12px',
    lineHeight: '18px',
    fontFamily: 'Lato-Medium, sans-serif',
  },
  statusContainer: {
    borderRadius: '6px',
    padding: '2px 8px',
    fontFamily: 'Lato-Bold, sans-serif',
    fontSize: '12px',
    lineHeight: '20px',
    height: 'fit-content',
    textTransform: 'capitalize',
  },
  openStatus: {
    backgroundColor: Colors.warningTransparent,
    color: Colors.warningDark,
  },
  pendingStatus: {
    backgroundColor: Colors.blueTransparent,
    color: Colors.mainBlue,
  },
  resolvedStatus: {
    backgroundColor: Colors.greenTransparent,
    color: Colors.darkGreen,
  },
  closeIconBtn: {
    justifyItems: 'flex-end',
    marginLeft: 'auto',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    height: 'fit-content',
    width: 'fit-content',
    fontSize: '18px',
    color: Colors.subtitleGrey,
    padding: 0,
  },
  imagesContainer: {
    display: 'flex',
    columnGap: '16px',
    flexWrap: 'wrap',
    marginTop: '40px',
  },
  file: {
    borderRadius: '8px',
    width: '120px',
    height: 'auto',
  },
}));

export default useStyles;
