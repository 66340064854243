import axios from 'axios';

import Config from '../config/index';

const instance = axios.create({ baseURL: Config.baseOrderUrl, withCredentials: true });

export const getSubscriptions = ({ tenant = undefined, limit, offset, sortModel = null }) => {
    const isAsc = sortModel?.sort === 'asc';
    const sortingParams = isAsc ? sortModel?.field : `-${sortModel?.field}`;
    const sort = sortModel ? sortingParams : null;
    return instance.get('/v1/order/subscription', {
      params: {
        limit,
        offset,
        'filter[tenant]': tenant,
        populate: 'order_details',
        sort,
      },
    });
  };
