import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Feature } from '@zaveit/feature';
import { Tile, Tooltip } from '@zaveit/uikit';
import { StatisticsCards, StatisticCardsAccess } from 'components/constants';
import { TStatisticValues } from 'components/types';
import useStyles from './Statistic.styles';

type TStatistic = {
  values: TStatisticValues;
  isMsp: boolean;
  isLoading: boolean;
};

function Statistic({ values, isMsp, isLoading = false }: TStatistic) {
  const classes = useStyles();
  const history = useHistory();

  const role = useMemo(
    () => (isMsp ? StatisticCardsAccess.provider : StatisticCardsAccess.consumer),
    [isMsp],
  );

  const handleTileClick = (path: string) => {
    history.push(path);
  };

  return (
    <div className={classes.staticticsContainer}>
      {StatisticsCards.map((card) => {
        const hasRights = card.access === role || card.access === StatisticCardsAccess.all;
        const hasValue = values[card.key] || values[card.key] === 0;

        return hasRights ? (
          <Feature feature={card.feature} key={card.title}>
            <Tooltip title={card?.tooltip || ''} placement="bottom-start">
              <Tile
                isLoading={isLoading}
                className={!hasValue && classes.comingSoonTitle}
                title={card.title}
                value={hasValue ? values[card.key] : 'Coming soon'}
                Icon={() => (
                  <img
                    src={card.icon}
                    alt=""
                    className={!hasValue ? classes.placeholderIcon : ''}
                  />
                )}
                isRateEnabled={false}
                onClick={card?.path ? () => handleTileClick(card?.path) : undefined}
              />
            </Tooltip>
          </Feature>
        ) : null;
      })}
    </div>
  );
}

export default Statistic;
