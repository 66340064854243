// @ts-nocheck
import { makeStyles } from '@material-ui/core';

import tableStyles from 'components/shared/styles/commonTable.styles';
import 'styles/fonts.scss';

const useStyles = makeStyles(() => ({
  ...tableStyles(),
  titleContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '7px',
  },
  navContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
