import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import classnames from 'classnames';
import { Tooltip } from '@zaveit/uikit';

import Score from 'components/shared/Score/Score';
import { TAccount } from 'components/types';

import useStyles from './CustomersTable.styles';

type TCustomerTableRow = {
  customer?: TAccount;
};

const CustomerTableRow = ({ customer }: TCustomerTableRow) => {
  const classes = useStyles();

  return (
    <>
      <TableRow
        classes={{ root: classes.bodyRow }}
        role="button"
        aria-hidden="true"
        onClick={() =>
          window.location.replace(
            `https://${window.location.host}/account-manager/accounts/${customer?._id}`,
          )
        }
      >
        <TableCell
          align="left"
          classes={{
            root: classnames(classes.cellRoot, classes.cellFirst),
          }}
        >
          <Tooltip title={customer.name}>
            <span>{customer.name}</span>
          </Tooltip>
        </TableCell>
        <TableCell
          align="center"
          classes={{
            root: classes.cellRoot,
          }}
        >
          {customer.arr}
        </TableCell>
        <TableCell
          align="center"
          classes={{
            root: classes.cellRoot,
          }}
        >
          {customer.users}
        </TableCell>
        <TableCell
          align="center"
          classes={{
            root: classnames(classes.cellRoot, classes.cellLast),
          }}
        >
          <Score score={Number(customer?.risc_score?.toFixed(1))} />
        </TableCell>
      </TableRow>
    </>
  );
};

CustomerTableRow.defaultProps = {
  customer: null,
};

export default CustomerTableRow;
