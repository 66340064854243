import { makeStyles } from '@material-ui/core';

import { Shadows } from 'styles/constants';
import Colors from 'styles/Color.styles';
import 'styles/fonts.scss';

type Props = {
  iconColor?: string;
  iconBcColor?: string;
};

const useStyles = makeStyles(() => ({
  container: {},
  arrowBtnStyles: {
    top: '52px',
    right: '-2px',
    transform: 'rotate(90deg)',
  },
  arrowBtnStylesOpen: {
    transform: 'rotate(180deg)',
  },
  accordionTitle: {
    fontFamily: 'Poppins-Regular, sans-serif',
    fontSize: '18px',
    lineHeight: '28px',
    color: Colors.backgroundGrey,
    marginLeft: '19px',
  },
  defaultIcon: {
    fontSize: '28px',
    color: Colors.shadowGrey,
  },
  completedIcon: {
    color: Colors.mainGreen,
  },
  accordionTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: '24px',
  },
  taskDescription: {
    marginTop: '30px',
    color: Colors.backgroundGrey,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Lato-Normal, sans-serif',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '20px',
  },
  accordionContainer: {
    boxShadow: 'none',
    margin: 0,
    borderTop: `solid 1px ${Colors.shadowGrey}`,
    borderRadius: '0px',
    '&:first-child': {
      borderTop: 'none',
    },
  },
  tasksContainer: {
    boxShadow: Shadows.cardShadow,
    borderRadius: '16px',
    marginTop: '40px',
  },
  title: {
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '32px',
    lineHeight: '48px',
    color: Colors.backgroundGrey,
    textAlign: 'center',
    marginTop: '40px',
  },
  description: {
    color: Colors.subtitleGrey,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Lato-Normal, sans-serif',
    marginTop: '8px',
    textAlign: 'center',
  },
  linkBtn: {
    display: 'block',
    margin: '0 auto',
    color: Colors.mainGreen,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '14px',
    lineHeight: '22px',
    fontFamily: 'Lato-Normal, sans-serif',
    border: 'none',
    backgroundColor: 'transparent',
    marginTop: '40px',
    transition: 'color 0.3s',
    '&:hover': {
      color: Colors.darkestGreen,
      transition: 'color 0.3s',
    },
  },
  iconContainer: {
    borderRadius: '12px',
    width: '48px',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ({ iconBcColor } : Props) => iconBcColor,
  },
  icon: {
    fontSize: '23px',
    color: ({ iconColor } : Props) => iconColor,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    color: Colors.mainGreen,
    fontSize: '16px',
    lineHeight: '24px',
    fontFamily: 'Lato-Bold, sans-serif',
    marginTop: '20px',
    textDecoration: 'underline',
    transition: 'color 0.3s',
    '&:hover': {
      color: Colors.darkestGreen,
      transition: 'color 0.3s',
    },
  },
  imageContainer: {
display: "flex",
justifyContent: "center",
paddingTop: "50px",
paddingBot: "50px",
  },
  gettingStartedContainer: {
    transition: 'padding-left 0.2s ease-in-out',
    maxWidth: '800px',
    margin: '0px auto 50px auto',
    paddingTop: '60px',
    paddingLeft: '20px',
  }
}));

export default useStyles;
