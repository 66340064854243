import { makeStyles } from '@material-ui/core';
import Colors from 'styles/Color.styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.white,
    borderRadius: '16px',
    [theme.breakpoints.only('xs')]: {
      borderRadius: '0px',
    },
  },
  backDrop: {
    backgroundColor: 'rgba(33, 37, 46, 0.7)',
  },
  modalSubtitle: {
    fontWeight: 'normal',
    letterSpacing: '1px',
    fontSize: '16px',
    margin: '0px 0px 40px 0px',
    color: Colors.subtitleGrey,
    textAlign: 'center',
    lineHeight: '24px',
  },
  modalTitle: {
    fontWeight: 600,
    fontSize: '32px',
    margin: '8px 0px',
    color: Colors.backgroundGrey,
    textAlign: 'center',
  },
  modalContainer: {
    position: 'relative',
    padding: '40px',
    width: '500px',
    height: '330px',
    boxSizing: 'border-box',
    [theme.breakpoints.only('xs')]: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    '@media (max-width: 350px)': {
      padding: '10px',
    },
  },
}));

export default useStyles;
