import { getRisc, getFindings } from 'api-client/risc';
import { getCustomers } from 'api-client/user';
import { Ranges, ServicesStatuses, DefaultSortingValue } from 'components/constants';
import { getExtendedAccount, getAccounts } from 'api-client/account';
import { getBackupTargets, getVsaasTargets } from 'api-client/target';
import { getTickets } from 'api-client/support';
import { TFinding } from 'components/types';

export const getDashboardData = async (tenant, isMsp) => {
  try {
    const riscPromise = getRisc(tenant);
    const findingsPromise = getFindings(tenant, null, null, 5);
    const supportPromise = getTickets({ status: 'open', tenant });

    if (isMsp) {
      const customersPromise = getCustomers();
      const accountsPromise = getAccounts({ tenant });

      const [riscData, findingData, customersData, accountsData, ticketsData] = await Promise.all([
        riscPromise,
        findingsPromise,
        customersPromise,
        accountsPromise,
        supportPromise,
      ]);

      return {
        risc: riscData,
        findings: findingData,
        customers: customersData,
        accounts: accountsData?.data,
        tickets: ticketsData,
      };
    }

    const backupPromise = getBackupTargets();
    const vsaasPromise = getVsaasTargets();

    const [riscData, findingData, backupData, vsaasData, ticketsData] = await Promise.all([
      riscPromise,
      findingsPromise,
      backupPromise,
      vsaasPromise,
      supportPromise,
    ]);

    return {
      risc: riscData,
      findings: findingData,
      backup: backupData,
      vsaas: vsaasData,
      tickets: ticketsData,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const getCustomersData = async (tenant) => {
  try {
    const riscPromise = getRisc(tenant, true);
    const customersPromise = getExtendedAccount(tenant);

    const [riscData, customersData] = await Promise.all([riscPromise, customersPromise]);

    return {
      risc: riscData,
      customers: customersData?.data,
    };
  } catch (error) {
    return error;
  }
};

export const updateProviderFindingsData = (findings, customers) =>
  findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    customer: customers?.find((customer) => customer.id === finding.metadata.child_tenant)?.name,
  }));

export const updateConsumerFindingsData = (findings, targets) => {
  return findings?.slice(Ranges.tables.min, Ranges.tables.max).map((finding) => ({
    ...finding,
    target: targets.find((target) => target._id === finding.metadata.target)?.title,
  }));
};

export const getTargetsData = (findingsData, backup = [], vsaas = []) => {
  const targetsData = [...backup, ...vsaas];
  const findings = new Map<string, TFinding>(
    findingsData?.map((finding) => [finding?.metadata?.target, finding]),
  );

  return targetsData
    ?.map((target) => {
      const currFinding = findings.get(target._id);

      return {
        _id: target?.id,
        title: target?.displayName || target?.name,
        user: target?.account?.name,
        metadata: { application: currFinding?.metadata?.application || 'default' },
        service: ServicesStatuses[currFinding?.metadata?.application]?.title,
        risc_score: currFinding?.risc_score,
      };
    })
    .sort((a, b) => (b.risc_score || DefaultSortingValue) - (a.risc_score || DefaultSortingValue))
    .slice(Ranges.tables.min, Ranges.tables.max);
};
