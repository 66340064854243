import { makeStyles } from '@material-ui/core';
import Colors from 'styles/Color.styles';

const useStyles = makeStyles(() => ({
  container: {
    height: 'calc(100vh - 236px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: { fontSize: '144px', color: Colors.lightIconGrey },
  title: {
    margin: '16px auto',
    fontFamily: 'Poppins-Semibold, sans-serif',
    fontSize: '48px',
    lineHeight: '64px',
    color: Colors.backgroundGrey,
  },
  description: {
    fontFamily: 'Lato-Normal, sans-serif',
    fontSize: '18px',
    lineHeight: '22px',
    color: Colors.subtitleGrey,
  },
}));

export default useStyles;
